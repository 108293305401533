var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Mission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Mission","required":"","outlined":"","dense":""},model:{value:(_vm.air_traffic_controls.mission_id),callback:function ($$v) {_vm.$set(_vm.air_traffic_controls, "mission_id", $$v)},expression:"air_traffic_controls.mission_id"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Take Off Call","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Take Off Call","required":"","outlined":"","dense":""},model:{value:(_vm.air_traffic_controls.take_off_call),callback:function ($$v) {_vm.$set(_vm.air_traffic_controls, "take_off_call", $$v)},expression:"air_traffic_controls.take_off_call"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Landing Call","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Landing Call","required":"","outlined":"","dense":""},model:{value:(_vm.air_traffic_controls.landing_call),callback:function ($$v) {_vm.$set(_vm.air_traffic_controls, "landing_call", $$v)},expression:"air_traffic_controls.landing_call"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Flight Plan Required","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Flight Plan Required","required":"","outlined":"","dense":""},model:{value:(_vm.air_traffic_controls.flight_plan_required),callback:function ($$v) {_vm.$set(_vm.air_traffic_controls, "flight_plan_required", $$v)},expression:"air_traffic_controls.flight_plan_required"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Camu Reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Camu Reference","required":"","outlined":"","dense":""},model:{value:(_vm.air_traffic_controls.camu_reference),callback:function ($$v) {_vm.$set(_vm.air_traffic_controls, "camu_reference", $$v)},expression:"air_traffic_controls.camu_reference"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Flight Plan Reference","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Flight Plan Reference","outlined":"","dense":""},model:{value:(_vm.air_traffic_controls.flight_plan_reference),callback:function ($$v) {_vm.$set(_vm.air_traffic_controls, "flight_plan_reference", $$v)},expression:"air_traffic_controls.flight_plan_reference"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Ssr","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Ssr","outlined":"","dense":""},model:{value:(_vm.air_traffic_controls.ssr),callback:function ($$v) {_vm.$set(_vm.air_traffic_controls, "ssr", $$v)},expression:"air_traffic_controls.ssr"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Field 18 Vfr","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Field 18 Vfr","outlined":"","dense":""},model:{value:(_vm.air_traffic_controls.field_18_vfr),callback:function ($$v) {_vm.$set(_vm.air_traffic_controls, "field_18_vfr", $$v)},expression:"air_traffic_controls.field_18_vfr"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Field 18 Rmk","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Field 18 Rmk","outlined":"","dense":""},model:{value:(_vm.air_traffic_controls.field_18_rmk),callback:function ($$v) {_vm.$set(_vm.air_traffic_controls, "field_18_rmk", $$v)},expression:"air_traffic_controls.field_18_rmk"}})]}}],null,true)}),_c('v-btn',{staticClass:"ma-10 pa-5",attrs:{"type":"submit","color":"primary","disabled":invalid}},[_vm._v(" "+_vm._s("SAVE")+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }